import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import helpers from "../utils/helpers";
import AuthContext from "../context/AuthContext";
import { get, isEmpty, startCase } from "lodash";
import { useParams } from "react-router-dom";
import moment from "moment";
const CasinoListing = () => {
  let { user } = useContext(AuthContext);
  const parmas = useParams();
  var previousDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  var currentDate = moment().format("YYYY-MM-DD");
  let objSelect = {
    aura: -2,
    awc: -1,
    egt: -3,
  };
  const [filter, setFilter] = useState({
    type: "3",
    betType: "casino",
    sortOrder: "asc",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    casionType: "",
    last: 25,
    status: "completed",
    page: 1,
    limit: 100,
  });
  const [data, setData] = useState([]);
  const getData = async (type = "search") => {
    const { status, data: response_users } = await apiGet(
      apiPath.getCasinoNew,
      type == "search" ? filter : type
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users?.results);
      }
    }
  };

  useEffect(() => {
    if (filter?.casionType !== parmas?.type) {
      getData({ ...filter, casionType: objSelect[parmas?.type] });
      setFilter((prev) => {
        return { ...prev, casionType: objSelect[parmas?.type] };
      });
    }
  }, [parmas]);

  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <div className="db-sec">
            <h2 className="common-heading">
              Casino {startCase(parmas?.type)} Bet List
            </h2>
          </div>
          <div className="inner-wrapper">
            <div className="common-container">
              <Form className="bet_status bet-list-live">
                <div className="bet_outer">
                  <Col lg={2} sm={3} className="mb-lg-0 mb-3">
                    <div className="bet-sec bet-period">
                      <Form.Label
                        style={{ fontWeight: "600" }}
                        className="me-2"
                      >
                        Period From
                      </Form.Label>
                      <Form.Group className="form-group">
                        <Form.Control
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              fromPeriod: e.target.value,
                              filterByDay: "",
                            })
                          }
                          max={new Date().toISOString().split("T")[0]}
                          value={filter.fromPeriod}
                          type="date"
                        />
                        <Form.Control
                          className="small_form_control"
                          type="text"
                          placeholder="00:00"
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={2} sm={3} className="mb-lg-0 mb-3">
                    <div className="bet-sec bet-period">
                      <Form.Label
                        style={{ fontWeight: "600" }}
                        className="me-2"
                      >
                        Period To
                      </Form.Label>
                      <Form.Group className="form-group">
                        <Form.Control
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              toPeriod: e.target.value,
                              filterByDay: "",
                            })
                          }
                          min={
                            filter?.fromPeriod
                              ? new Date(filter?.fromPeriod)
                                  .toISOString()
                                  .split("T")[0]
                              : new Date()
                          }
                          disabled={filter.fromPeriod ? false : true}
                          max={new Date().toISOString().split("T")[0]}
                          value={filter.toPeriod}
                          type="date"
                        />
                        <Form.Control
                          className="small_form_control"
                          type="text"
                          placeholder="23:59"
                          disabled
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </div>
                <div className="bet_outer">
                  <div className="bet-sec" style={{ flex: "unset" }}>
                    <Form.Control
                      type="text"
                      placeholder="Search BetId"
                      value={filter?.betId}
                      onChange={(e) =>
                        setFilter({ ...filter, betId: e.target.value })
                      }
                    />
                  </div>
                  <div className="bet-sec" style={{ flex: "unset" }}>
                    <Form.Label
                      style={{ fontWeight: "600" }}
                    >{`Stake >=`}</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Stake"
                      value={filter?.stake}
                      onChange={(e) =>
                        setFilter({ ...filter, stake: e.target.value })
                      }
                    />
                  </div>

                  <div className="bet-sec bet-period">
                    <Form.Label style={{ fontWeight: "600" }}>Last</Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, last: e.target.value })
                      }
                      value={filter.last}
                      aria-label="Default select example"
                    >
                      <option value={25}>25 Txn</option>
                      <option value={50}>50 Txn</option>
                      <option value={100}>100 Txn</option>
                      <option value={200}>200 Txn</option>
                      <option value={""}>All</option>
                    </Form.Select>
                  </div>

                  <div className="bet-sec">
                    <Form.Label
                      style={{ fontWeight: "600" }}
                      className="mt-2 me-2"
                    >
                      Sort By
                    </Form.Label>
                    <Form.Select
                      className="small_select"
                      onChange={(e) =>
                        setFilter({ ...filter, status: e.target.value })
                      }
                      value={filter.status}
                      aria-label="Default select example"
                    >
                      <option value="completed">Bet Settled Time</option>
                      <option value="suspend">Bet Cancelled Time</option>
                      <option value="voided">Bet Voided Time</option>
                    </Form.Select>
                  </div>

                  <div style={{ display: "flex" }}>
                    <Button
                      className="theme_dark_btn"
                      style={{ marginRight: "10px" }}
                      onClick={() => getData("search")}
                    >
                      Get History
                    </Button>
                    <Button
                      className="theme_light_btn"
                      onClick={() => {
                        setFilter({
                          type: "3",
                          betType: "casino",
                          sortOrder: "asc",
                          fromPeriod: previousDate,
                          toPeriod: currentDate,
                          casionType: parmas?.type,
                          last: 25,
                          status: "completed",
                          page: 1,
                          limit: 100,
                        });
                        getData({
                          type: "3",
                          betType: "casino",
                          sortOrder: "asc",
                          fromPeriod: previousDate,
                          toPeriod: currentDate,
                          casionType: parmas?.type,
                          last: 25,
                          status: "completed",
                          page: 1,
                          limit: 100,
                        });
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="account-table batting-table">
                <div className="responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th scope="col">PL ID</th>
                        <th scope="col"> Bet ID</th>
                        <th scope="col">Bet placed</th>
                        <th scope="col">IP Address </th>
                        <th scope="col">Market</th>
                        <th scope="col">Selection</th>
                        <th scope="col">Type</th>
                        <th scope="col">Stake </th>
                      </tr>
                    </thead>

                    {data?.data?.length > 0 ? (
                      data?.data?.map((item) => {
                        return (
                          <tr>
                            <td>{item?.clientName || "-"}</td>
                            <td> {item?.casinoBetId}</td>
                            <td>
                              {" "}
                              {helpers.dateFormat(
                                item?.timeInserted,
                                user.timeZone
                              )}
                            </td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "-"}
                            </td>
                            <td className="text-start">
                              <strong>{item?.casinoName}</strong>
                              <span className="angle_unicode">▸</span>
                              {item?.casinoType}{" "}
                            </td>
                            <td> {item?.platformTxId}</td>
                            <td> {item?.gameCode} </td>
                            <td> {item?.betAmount || "-"} </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={12}>
                          <span>You have no bets in this time period.</span>
                        </td>
                      </tr>
                    )}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default CasinoListing;
